import _objectSpread from "/Users/dev2023/hotel/hotel_admin_template/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import _variables from '@/styles/variables.scss';
import { mapGetters } from 'vuex';
export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['appInfo'])), {}, {
    variables: function variables() {
      return _variables;
    }
  })
};