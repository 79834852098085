import _objectSpread from "/Users/dev2023/hotel/hotel_admin_template/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import RightPanel from '@/components/RightPanel';
import { AppMain, Navbar, Settings, Sidebar, TagsView } from './components';
import ResizeMixin from './mixin/ResizeHandler';
import { mapGetters, mapState } from 'vuex';
import _variables from '@/styles/variables.scss';
import { checkWithdraw, getWithdraw } from '@/api/withdraw';
export default {
  name: 'Layout',
  data: function data() {
    return {
      withdrawId: 0,
      rechargeId: 0
    };
  },
  components: {
    AppMain: AppMain,
    Navbar: Navbar,
    RightPanel: RightPanel,
    Settings: Settings,
    Sidebar: Sidebar,
    TagsView: TagsView
  },
  mixins: [ResizeMixin],
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapGetters(['roles'])), mapState({
    sidebar: function sidebar(state) {
      return state.app.sidebar;
    },
    device: function device(state) {
      return state.app.device;
    },
    showSettings: function showSettings(state) {
      return state.settings.showSettings;
    },
    needTagsView: function needTagsView(state) {
      return state.settings.tagsView;
    },
    fixedHeader: function fixedHeader(state) {
      return state.settings.fixedHeader;
    }
  })), {}, {
    classObj: function classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      };
    },
    variables: function variables() {
      return _variables;
    }
  }),
  methods: {
    handleClickOutside: function handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', {
        withoutAnimation: false
      });
    }
  },
  created: function created() {
    var _this = this;

    if (this.roles.includes('kf') || this.roles.includes('admin') || this.roles.includes('webadmin')) {
      setInterval(function () {
        /*
                this.$notify({
                  message: '恭喜你，这是一条成功消息',
                  type: 'success',
                  position: 'bottom-right'
                })
                var ttsAudio = document.getElementById('s1');
                ttsAudio.play();
                */
        checkWithdraw().then(function (res) {
          var have_new = false;

          if (res.code == 200) {
            if (res.data.recharge > 0 && res.data.recharge != _this.rechargeId) {
              _this.rechargeId = res.data.recharge;
              have_new = true;

              _this.$notify({
                duration: 6000,
                message: '有新的【充值】，请及时处理',
                type: 'success',
                position: 'bottom-right'
              });
            }

            if (res.data.withdraw > 0 && res.data.withdraw != _this.withdrawId) {
              setTimeout(function () {
                _this.$notify({
                  duration: 6000,
                  message: '有新的【提现】，请及时处理',
                  type: 'success',
                  position: 'bottom-right'
                });
              }, 100);
              have_new = true;
              _this.withdrawId = res.data.withdraw;
            }

            if (have_new == true) {
              console.log("1");
              var ttsAudio = document.getElementById('s1');
              ttsAudio.play();
            }
          }
        });
      }, 5000);
    }
  }
};